import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const banner = {
    title: "クッキー＆SSLスキャナー",
    tagline: "Cookie & SSL Scannerでサイト上のあらゆるクッキーとトラッキング技術をスキャンします"
}

export const features = {
    image: (<StaticImage title='クッキー＆SSLスキャナー' src={"../../static/images/feature/cookie-ssl-scanner/featureImage.png"} width={530} quality={100} alt="Cookie SSL Scanner Feature Image"  />),
    contents: [
        {
            title: "クッキー＆SSLウェブサイトスキャナー",
            paragraph: (<><p>Secure Privacyの非常に強力なスキャナーは、カスタマイズが可能で、ログページの裏側をスキャンする機能も備えています。この高度なスキャニングシステムは：</p>
                <ul>
                    <li>あなたのウェブサイトで使われている全てのクッキーとトラッカーを検出します。</li>
                    <li>各クッキーの種類、期間、プロバイダー、目的を記載した詳細なクッキー宣言を生成します。</li>
                    <li>ユーザーがGDPRに準じた同意の選択をし終わるまで、あらゆるクッキーとトラッカーを自動的にブロックします。</li>
                    <li>ユーザーにオプトアウトオプションを表示します。</li>
                </ul>
            </>)
        },
    ]
}

