import * as React from "react";
import Layout from "../../components/common/layout";
import BannerWithTextBg from "../../components/common/banner-with-text-bg";
import TwoColumnContentSection from "../../components/common/banner-with-two-columns";
import { features, banner } from '../../contents/cookie-ssl-scanner';
import Meta from "../../components/common/meta/SEO";

const CookieSSLScanner = () => {
  return (
    <Layout>
        <BannerWithTextBg contents={banner} />
        <div className="mt-20 mb-24">
            <TwoColumnContentSection image={features.image} contents={features.contents} isColReverse={false} />
        </div>
    </Layout>
  );
};

export default CookieSSLScanner;

export const Head = ({ location }) => {
  const meta = {
    title: "クッキー＆SSLスキャナー｜Secure Privacy正規販売代理店 DXable",
    description: "Secure PrivacyのアドバンストスキャンシステムによるWebサイトの自動化と継続的なスキャンにより、GDPR、CCPA、LGPDのコンプライアンスを確保することができます。",
    pathname: location.pathname,
  }
  return (<Meta meta={meta} />)
}